export default [
  {
    label: '通用模板',
    options: [
      {
        label: '通用模板1',
        value: '/template/v1'
      }
    ]
  },
]