<template>
  <el-dialog :title="params.data ? '编辑' : '添加'" :visible.sync="params.show" fullscreen  :before-close="close" size="60%" :with-header="false" @open="openDialog">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item v-if="!ruleForm.menu_id" label="菜单类型" prop="multilevel">
        <el-select :disabled="!!params.data" v-model="ruleForm.multilevel" @change="setRules" placeholder="请选择">
          <el-option label="左菜单" :value="0"></el-option>
          <el-option label="右菜单" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="路由类型" prop="type">
        <el-select v-model="ruleForm.type" @change="routeTypeChange" placeholder="请选择">
          <el-option label="单页面路由" :value="1"></el-option>
          <el-option label="通用页面路由" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="路由地址" prop="route">
        <div class="route-edit-view">
          <el-select v-show="!userDefined" clearable v-model="ruleForm.route" placeholder="请选择路由">
            <el-option-group
              v-for="(group, index) in routeList"
              :key="index"
              :label="group.label">
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
          <el-input v-show="userDefined" v-model="ruleForm.route" placeholder="请输入路由地址"></el-input>
          <el-button @click="userDefined = !userDefined">{{ userDefined ? '路由列表' : '自定义' }}</el-button>
        </div>
      </el-form-item>
      <el-form-item label="导航标题(中文)" prop="title_cn">
        <el-input v-model="ruleForm.title_cn"></el-input>
      </el-form-item>
      <el-form-item label="导航标题(英文)" prop="title_us">
        <el-input v-model="ruleForm.title_us"></el-input>
      </el-form-item>

      <el-form-item v-if="params.tier == 2" label="是否加粗" prop="highlight">
        <el-select v-model="ruleForm.highlight" placeholder="请选择">
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item v-if="params.tier == 2" label="子菜单分组" prop="menu_group">
        <el-select v-model="ruleForm.menu_group" placeholder="请选择">
          <el-option
            v-for="item in groupIndexList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="ruleForm.type == 2" label="网页内容(中文)" prop="content_cn">
        <tinymce v-model="ruleForm.content_cn"></tinymce>
      </el-form-item>
      <el-form-item v-if="ruleForm.type == 2" label="网页内容(英文)" prop="content_us">
        <tinymce v-model="ruleForm.content_us"></tinymce>
      </el-form-item>
      <el-form-item v-if="ruleForm.type == 2" label="关联" prop="correlation">
        <div class="correlation-select-view">
          <el-select v-model="correlationType" placeholder="请选择">
            <el-option label="产品" :value="1"></el-option>
            <el-option label="解决方案" :value="2"></el-option>
            <el-option label="新闻与动态" :value="3"></el-option>
            <el-option label="行业案例" :value="4"></el-option>
          </el-select>
          <el-select
            v-model="correlationValue"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading">
            <el-option
              v-for="item in correlationOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-button @click="addCorrelationValue">添加</el-button>
        </div>
        <el-tag @close="handleClose(index)" closable v-for="(tag, index) in ruleForm.correlation" :key="index">{{ tag.title_cn }}</el-tag>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { editMenu, addMenu, docSearch } from '@/api/menus/menus'
import tinymce from '@/components/common/tinymce.vue'
import clientRouteList from '@/utils/clientRouteList'
import templateRouteList from '@/utils/templateRouteList'
export default {
  components: {
    tinymce
  },
  data() {
    return {
      ruleForm: {
        id: null,
        type: 2,
        route: '',
        title_cn: '',
        title_us: '',
        multilevel: 1,
        highlight: 0,
        menu_id: null,
        menu_group: 0,
        content_cn: '',
        content_us: '',
        correlation: []
      },
      groupIndexList: [],
      rulesList: {
        // 添加编辑一级导航 multilevel=0 AND type=1
        1: {
          route: [
            { required: true, message: '请输入路由页面', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_cn: [
            { required: true, message: '请输入导航标题(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_us: [
            { required: true, message: '请输入导航标题(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
        },
        // 添加编辑一级导航 multilevel=0 AND type=2
        2: {
          route: [
            { required: true, message: '请输入路由页面', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_cn: [
            { required: true, message: '请输入导航标题(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_us: [
            { required: true, message: '请输入导航标题(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          content_cn: [
            { required: true, message: '请输入网页内容(中文)', trigger: 'blur' },
          ],
          content_us: [
            { required: true, message: '请输入网页内容(英文)', trigger: 'blur' },
          ],
        },
        // 添加编辑一级导航 multilevel=1
        3: {
          title_cn: [
            { required: true, message: '请输入导航标题(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_us: [
            { required: true, message: '请输入导航标题(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
        },
        // 添加编辑二级导航 multilevel=1 AND type=1
        4: {
          route: [
            { required: true, message: '请输入路由页面', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_cn: [
            { required: true, message: '请输入导航标题(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_us: [
            { required: true, message: '请输入导航标题(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          menu_id: [
            { required: true, message: '没有一级导航id', trigger: 'blur' },
          ],
          menu_group: [
          { required: true, message: '请选择导航所属分组', trigger: 'blur' },
          ]
        },
        // 添加编辑二级导航 multilevel=1 AND type=2
        5: {
          route: [
            { required: true, message: '请输入路由页面', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_cn: [
            { required: true, message: '请输入导航标题(中文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          title_us: [
            { required: true, message: '请输入导航标题(英文)', trigger: 'blur' },
            { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
          ],
          menu_id: [
            { required: true, message: '没有一级导航id', trigger: 'blur' },
          ],
          menu_group: [
            { required: true, message: '请选择导航所属分组', trigger: 'blur' },
          ],
          content_cn: [
            { required: true, message: '请输入网页内容(中文)', trigger: 'blur' },
          ],
          content_us: [
            { required: true, message: '请输入网页内容(英文)', trigger: 'blur' },
          ],
        }
      },
      rules: {},
      correlationType: 1,
      correlationValue: '',
      correlationOptions: [],
      loading: false,
      userDefined: false
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  computed: {
    routeList () {
      if (this.ruleForm.type === 1) {
        return clientRouteList
      } else if (this.ruleForm.type === 2) {
        return templateRouteList
      }
    }
  },
  methods: {
    handleClose(index) {
      this.ruleForm.correlation.splice(index, 1)
    },
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = {
        id: null,
        type: 2,
        route: '',
        title_cn: '',
        title_us: '',
        multilevel: 1,
        highlight: 0,
        menu_id: null,
        menu_group: 0,
        content_cn: '',
        content_us: '',
        correlation: []
      }
      this.$emit('close')
    },
    openDialog () {
      if (this.params.groupIndexList) {
        this.groupIndexList = this.params.groupIndexList
      }
      this.groupIndexList.push({
        label: '分组' + (this.groupIndexList.length+1),
        value: this.groupIndexList.length
      })
      if (this.params.data) {
        this.ruleForm = this.params.data
      }
      if (this.params.menu_id) {
        this.ruleForm.menu_id = this.params.menu_id
      }
      if (this.params.multilevel !== undefined) {
        this.ruleForm.multilevel = this.params.multilevel
      }
      this.setRules()
    },
    routeTypeChange () {
      this.ruleForm.route = ''
      this.setRules()
    },
    setRules () {
      if (this.params.tier === 1) {
        if (this.ruleForm.multilevel === 0 && this.ruleForm.type == 1) {
          this.rules = this.rulesList[1]
        } else if (this.ruleForm.multilevel === 0 && this.ruleForm.type == 2) {
          this.rules = this.rulesList[2]
        } else if (this.ruleForm.multilevel === 1) {
          this.rules = this.rulesList[3]
        }
      } else {
        if (this.ruleForm.type === 1) {
          this.rules = this.rulesList[4]
        } else if (this.ruleForm.type === 2) {
          this.rules = this.rulesList[5]
        }
      }
    },
    async remoteMethod (keyword) {
      if (keyword !== '') {
        this.loading = true;
        const result = await docSearch({
          cur: 1,
          size: 100,
          keyword,
          type: this.correlationType
        })
        this.loading = false;
        if (!result.success) return this.$message.error(result.msg)
        this.correlationOptions = result.data.list.map(v => {
          return {
            label: v.title,
            value: v.id
          }
        })
      }
    },
    addCorrelationValue () {
      if (!this.correlationValue) {
        return this.$message.warning('请选择关联信息')
      }
      const title = this.correlationOptions[this.correlationOptions.findIndex(v => v.value === Number(this.correlationValue))].label
      const obj = {
        type: this.correlationType,
        id: this.correlationValue,
        title_cn: title,
      }
      console.log(obj);
      this.correlationValue = ''
      this.ruleForm.correlation.push(obj)
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.params.data ? await editMenu(this.ruleForm): await addMenu(this.ruleForm)
        if (result.success) {
          this.$message.success('修改成功')
          this.close()
        }
      })
    },
  },
}
</script>
<style scoped lang="less">
.el-form {
  margin: 20px;
}
.correlation-select-view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .el-select {
    margin-right: 10px;
  }
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
.route-edit-view {
  .el-input {
    max-width: 300px;
  }
  .el-button {
    margin-left: 20px;
  }
}
</style>
