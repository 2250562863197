<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>栏目管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col :span="24">
        <el-button type="primary" @click="appendClick">添加栏目</el-button>
      </el-col>
    </el-row>
    <el-container>
    <el-aside width="350px">
      <h4>左菜单</h4>
      <el-tree
        :data="labelList"
        :props="treeProps"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
        @node-click="treeClick">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button
              type="text"
              size="mini"
              @click="() => editClick(node, data)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => appendClick(node, data)">
              {{ data.button_add_text }}
            </el-button>
            <el-popconfirm title="确定删除？" @confirm="removeClick(node, data)">
              <el-button
                slot="reference"
                type="text"
                size="mini">
                删除
              </el-button>
            </el-popconfirm>
          </span>
        </span>
      </el-tree>
      <h4>右菜单</h4>
      <el-tree
        :data="menuList"
        :props="treeProps"
        node-key="id"
        default-expand-all
        @node-click="treeClick"
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span v-if="!(data.type == 'group')">
            <el-button
              type="text"
              size="mini"
              @click="() => editClick(node, data)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => appendClick(node, data)">
              {{ data.button_add_text }}
            </el-button>
            <el-popconfirm title="确定删除？" @confirm="removeClick(node, data)">
              <el-button
                slot="reference"
                type="text"
                size="mini">
                {{ data.button_del_text }}
              </el-button>
            </el-popconfirm>
          </span>
        </span>
      </el-tree>
    </el-aside>
    <el-main>
      <el-descriptions title="导航信息">
        <el-descriptions-item label="路由标题(中文)">{{ viewData.title_cn }}</el-descriptions-item>
        <el-descriptions-item label="路由标题(英文)">{{ viewData.title_us }}</el-descriptions-item>
        <el-descriptions-item label="类型">{{ viewData.type==1? '单页面路由' : '通用页面路由' }}</el-descriptions-item>
        <el-descriptions-item label="路径">{{ viewData.route }}</el-descriptions-item>
        <el-descriptions-item label="是否加粗">{{ !!viewData.highlight ? '是' : '否' }}</el-descriptions-item>
        <el-descriptions-item label="关联信息">
          <el-tag v-for="(tag, index) in viewData.correlation" :key="index">{{ tag.title_cn }}</el-tag>
        </el-descriptions-item>
    </el-descriptions>
    </el-main>
  </el-container>
  <add-edit-menus-dialog :params="addEditInfo" @close="addEditDialogClose"></add-edit-menus-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { deleteMenu } from '@/api/menus/menus'
import AddEditMenusDialog from './cnps/add-edit-menus-dialog.vue'
export default {
  data() {
    return {
      treeProps: {
        label: 'title_cn',
        children: 'children',
      },
      addEditInfo: {
        show: false,
        tier: 1, // 1：添加一级导航；2: 添加二级导航
        groupIndexList: [],
        data: null,
        menu_id: null
      },
      viewData: ''
    }
  },
  components: {
    AddEditMenusDialog
  },
  mounted() {
    this.getMenus()
  },
  computed: {
    ...mapState('menus', ['labelList', 'menuList'])
  },
  methods: {
    ...mapActions('menus', ['getMenus']),
    appendClick(node, data) {
      console.log(node, data);
      if (!node.level) {
        // 添加顶部栏
        this.addEditInfo.tier = 1
      } else {
        this.addEditInfo.tier = 2
        this.addEditInfo.groupIndexList = data.groupIndexList
        this.addEditInfo.menu_id = data.id
        this.addEditInfo.multilevel = data.multilevel
      }
      this.addEditInfo.show = true
    },
    async removeClick(node, data){
      const result = await deleteMenu(data.id)
      if (result.success) {
        this.$message.success('删除成功')
        this.getMenus()
      }
    },
    async editClick(node, data) {
      console.log(node, data);
      if (!node.level || node.level == 1) {
        // 编辑顶部栏
        this.addEditInfo.tier = 1
      } else if (node.level == 3) {
        this.addEditInfo.tier = 2
        this.addEditInfo.groupIndexList = data.groupIndexList
      }
      this.addEditInfo.data = data
      this.addEditInfo.show = true
    },
    // 关闭窗口
    addEditDialogClose () {
      this.addEditInfo.show = false;
      this.addEditInfo.tier = 1
      this.addEditInfo.data = null;
      this.addEditInfo.groupIndexList = []
      this.addEditInfo.menu_id = null
      this.getMenus()
    },
    treeClick (data) {
      console.log(data);
      if (data.type == "group") return
      this.viewData = data
    }
  },
  
}
</script>

<style lang="less" scoped>
  .el-button {
    margin-left: 10px;
  }
  .el-container {
    margin-top: 20px;
    background: #fff;
    padding: 0 20px;
    min-height: 80vh;
  }
</style>